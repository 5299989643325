<template>
  <g>
    <svg:style>
      .cls-4 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }
      
      .cls-4 {
        fill: url(#inox-gradient);
      }
    </svg:style>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth + 21.62"
      :y1="doorTopHeight1 + 148.47"
      :x2="doorLeftWidth + 117.91"
      :y2="doorTopHeight1 + 148.47"
      xlink:href="#handle-gradient"/>
    <g id="L09">
      <rect
        v-if="showInox"
        id="inox"
        class="cls-4"
        :x="inox1X"
        :y="doorTopHeight1 + 130.85"
        :width="inoxWidth"
        height="35.25"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.23,
      leaf2Offset: 7,
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    },
    inox1X() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leaf2Offset
    },
    inoxWidth() {
      return this.showBg ?
        this.doorWidth - 2 * this.inoxOffset :
        this.doorWidth - 2 * this.leaf2Offset
    },
  },
}
</script>
